import React from "react"
import Accordion from "./Accordion"

const AccordionGroup = ({ items = [] }) => {
  return (
    <div className="accordion-group">
      {items.map((item, i) => (
        <Accordion key={i} label={item.label} content={item.content} />
      ))}
    </div>
  )
}

export default AccordionGroup
