import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import BodySections from "../components/BodySections/BodySections"
import { Section, Container } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { AnchorLinksBar } from "../components/AnchorLinksBar"
import { Accordion } from "../components/Accordion"

const VolunteerPage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const { title, hero, anchors, sections, tableOfContents, newsletter } =
    data.allUniquePagesJson.nodes[0]

  const rootUrl = title.substring(0, title.length - 1)

  const pdfsHalf = Math.ceil(newsletter.pdfs.length / 2)
  const firstPdfHalf = newsletter.pdfs.slice(0, pdfsHalf)
  const secondPdfHalf = newsletter.pdfs.slice(pdfsHalf)

  return (
    <Layout className="volunteer-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />

      <Image wrapperClassName="gradient-curtain" publicId={hero.image} useAR />

      <AnchorLinksBar rootUrl={rootUrl} links={anchors} />

      <BodySections sections={sections} />

      <Section>
        <Container small>
          <Text text={tableOfContents.above} />
          <div className="volunteer__accordion mt-3 mb-3">
            <Accordion
              label={tableOfContents.accordion[0].label}
              content={tableOfContents.accordion[0].content}
            />
          </div>
          <Text text={tableOfContents.below} />
        </Container>
      </Section>

      <Section>
        <Container small>
          <Text
            as={newsletter.anchorLink.element}
            text={newsletter.anchorLink.text}
            id={newsletter.anchorLink.id}
          />
        </Container>
      </Section>

      <Section>
        <Container small>
          <Text text={newsletter.content} />
          {/* <div className="newsletter__pdfs mt-3">
            <div>
              {firstPdfHalf.map(pdf => (
                <a
                  key={pdf.href}
                  className="mb-2"
                  href={`https://res.cloudinary.com/nuvolum/image/upload/v1/${pdf.href}.pdf`}
                  target="_blank">
                  {pdf.title}
                </a>
              ))}
            </div>
            <div>
              {secondPdfHalf.map(pdf => (
                <a
                  key={pdf.href}
                  className="mb-2"
                  href={`https://res.cloudinary.com/nuvolum/image/upload/v1/${pdf.href}.pdf`}
                  target="_blank">
                  {pdf.title}
                </a>
              ))}
            </div>
          </div> */}
        </Container>
      </Section>
    </Layout>
  )
}

export const volunteerQuery = graphql`
  query volunteerquery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        hero {
          image
        }
        anchors {
          text
          id
        }
        sections {
          type
          text
          images
          colorBack
          element
          id
          imageText {
            reverse
            text
            image
          }
          container
        }
        tableOfContents {
          above
          below
          accordion {
            label
            content
          }
        }
        newsletter {
          anchorLink {
            element
            text
            id
          }
          content
          pdfs {
            title
            href
          }
        }
      }
    }
  }
`

export default VolunteerPage
