import React, { useState, useRef } from "react"
import { Text } from "../Core"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus"
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus"

const Accordion = ({ label, content }) => {
  const contentRef = useRef()

  const [contentHeight, setContentHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    if (isOpen) {
      setContentHeight(0)
    } else {
      setContentHeight(contentRef.current.scrollHeight + "px")
    }
    setIsOpen(!isOpen)
  }

  const styles = {
    maxHeight: contentHeight
  }

  return (
    <div className="accordion__outer">
      <div className={`accordion__inner ${isOpen ? "accordion__open" : ""}`}>
        <div className="accordion__label" onClick={toggleAccordion}>
          {label && <p>{label}</p>}
          <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} color="#545454" />
        </div>

        <div className="accordion__content" style={styles} ref={contentRef}>
          <Text text={content} />
        </div>
      </div>
    </div>
  )
}

export default Accordion
